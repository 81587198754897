@import "components/login";
@import "components/doctorProfile";

p {
    margin: 0;
    margin-top: 0.0625em;
    margin-bottom: 0.0625em;
}

h4 {
    margin: 0;
    margin-top: 0.0625em;
    margin-bottom: 0.0625em;
}

h5 {
    margin: 0;
    margin-top: 0.0625em;
    margin-bottom: 0.0625em;
}
