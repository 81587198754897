.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c8dde9;
}

.content-container {
    background-color: #e7eef1;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    // @media only screen and (min-width: 1151px) {
    //     width: 30%;
    // }

    // @media only screen and (max-width: 1150px) and (min-width: 600px) {
    //     width: 70%;
    // }

    // @media only screen and (max-width: 599px) and (min-width: 200px) {
    //     width: 95%;
    // }
}

.sub-container {
    width: 80%;

    @media only screen and (max-width: 500px) {
        width: 95%;
    }
}

.image-container {
    width: 400px;
    height: 400px;

    @media only screen and (max-width: 810px) {
        display: none;
    }
}

.homePageFastFashionList {
    background-color: white;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    /* height: 800px; */
    background-size: cover;
}

.text-on-image {
    position: relative;
    /* right: 0%; */
    /* left: 50%; */
    top: 15%;
}

.homeHeading {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 5vw;

    @media only screen and (min-width: 1151px) {
        text {
            color: #666666;
            font-size: 24px;
            text-align: center;
            font-family: "Poppins-Regular";
            width: 60%;
            margin: 10px;
        }

        .headingText {
            color: #188938;
            font-size: 50px;
            font-family: "Poppins-Bold";
            font-weight: 600;
            text-align: center;
            width: 80%;
        }
    }

    @media only screen and (max-width: 1150px) and (min-width: 600px) {
        text {
            color: #666666;
            font-size: 16px;
            text-align: center;
            font-family: "Poppins-Regular";
            width: 60%;
            margin: 10px;
        }

        .headingText {
            color: #188938;
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            width: 80%;
        }
    }

    @media only screen and (max-width: 599px) and (min-width: 200px) {
        text {
            color: #666666;
            font-size: 12px;
            text-align: justify;
            font-family: "Poppins-Regular";
            width: 80%;
            margin: 10px;
        }

        .headingText {
            color: #188938;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            width: 80%;
        }
    }
}

.how-it-works {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;

    @media only screen and (min-width: 1151px) {
        text {
            color: #666666;
            font-size: 24px;
            text-align: center;
            font-family: "Poppins-Bold";
            margin: 10px;
        }
    }

    @media only screen and (max-width: 1150px) and (min-width: 600px) {
        text {
            color: #666666;
            font-size: 16px;
            text-align: center;
            font-family: "Poppins-Bold";
            margin: 10px;
        }
    }

    @media only screen and (max-width: 599px) and (min-width: 200px) {
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: center;

        text {
            color: #666666;
            font-size: 18px;
            text-align: center;
            font-family: "Poppins-Bold";
            font-weight: 600;
            margin: 10px;
        }
    }
}

.menu-text {
    font-size: 16px;
    font-weight: 600;

    @media only screen and (max-width: 1200px) and (min-width: 600px) {
        font-size: 16px;
        font-weight: 600;
    }

    @media only screen and (max-width: 599px) and (min-width: 200px) {
        font-size: 16px;
        font-weight: 600;
    }
}

.category {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;

    @media only screen and (min-width: 1151px) {
        text {
            color: #666666;
            font-size: 24px;
            text-align: center;
            font-family: "Poppins-Bold";
            margin: 10px;
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 1150px) and (min-width: 600px) {
        text {
            color: #666666;
            font-size: 16px;
            text-align: center;
            font-family: "Poppins-Bold";
            margin: 10px;
        }
    }

    @media only screen and (max-width: 599px) and (min-width: 200px) {
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: center;

        text {
            color: #666666;
            font-size: 18px;
            text-align: center;
            font-family: "Poppins-Bold";
            font-weight: 600;
            margin: 10px;
        }
    }
}

.footer {
    // background-color: white;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    /* height: 800px; */
    background-size: cover;

    .footer-heading {
        color: white;
        font-weight: 600;
    }

    .data-text {
        color: white;
        margin: 1vw;
    }

    .social-icon {
        background-color: white;
        border-radius: 360%;
        // margin:1vw;
        height: 40px;
        width: 40px;
    }

    @media only screen and (min-width: 1151px) {
        .footer-heading {
            color: white;
            font-weight: 600;
            font-size: 28px;
        }

        .data-text {
            color: white;
            margin: 10px;
            font-size: 24px;
        }
    }

    @media only screen and (max-width: 1150px) and (min-width: 600px) {
        .footer-heading {
            color: white;
            font-weight: 600;
            font-size: 18px;
        }

        .data-text {
            color: white;
            margin: 1vw;
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 599px) and (min-width: 200px) {
        .footer-heading {
            color: white;
            font-weight: 600;
            font-size: 14px;
        }

        .data-text {
            color: white;
            margin: 1vw;
            font-size: 12px;
        }
    }
}
