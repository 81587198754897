.Not-Found-Container{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
}

.Not-Found-Heading{
    font-size: 44px;
}

.Not-Found-SubHeading{
    font-size: 24px;
}